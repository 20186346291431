/* Page Formatting */

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

header {
  display: grid;
  grid-template: 1fr / 1fr;
  height: 80px;
  width: 100%;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  background-color: rgb(254, 251, 239);
  position: fixed;
  z-index: 5;
}

.innerHeader {
  display: table;
  grid-column: 1 / span 1;
  justify-self: center;
  height: 100%;
  width: 90%;
}

.innerHeader .leftHeader {
  display: table-cell;
  height: 100%;
  align-content: center;
  float: left;
}

.innerHeader .logo {
  display: flex;
  flex: wrap;
  height: 100%;
  float: left;
}

.innerHeader .logo img {
  align-self: center;
  height: 60px;
}

.innerHeader .logo h2 {
  align-self: center;
  padding-left: 10px;
  color: rgb(40, 74, 89);
  font-family: "Reddit Sans Condensed";
  font-size: 1.9em;
 }


 /* Navigation Menu adapted from https://alvarotrigo.com/blog/hamburger-menu-css/ -Luke Embrey*/
@import url(https://fonts.googleapis.com/css?family=Raleway);
h2 {
  vertical-align: center;
  text-align: center;
}

html, body {
  margin: 0;
  height: 100%;
}

* {
  font-family: "Raleway";
  box-sizing: border-box;
}

.top-nav {
  display: table-cell;
  align-content: center;
  justify-self: start;
  background-color: rgb(254, 251, 239);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: rgb(40, 74, 89);
  height: 50px;
  padding: 1em;
  width: 50px;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin-right: 2.5em;
  overflow: hidden;
  font-weight: 600;
  font-family: "Reddit Sans Condensed";
  font-size: 1em;
  border-bottom: 2px solid rgb(254, 251, 239);;
  
}

.subList {
  color: white;
}

.menu li:hover {
  cursor: pointer;
  border-bottom: 2px solid rgb(40, 74, 89);
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: rgb(40, 74, 89);
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgb(254, 251, 239);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 800px) {
  .menu-button-container {
    display: flex;
  }
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
    
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: rgb(40, 74, 89);
    position: relative;
    top: 30px;
  }

  .menu > li:hover {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: rgb(40, 74, 89);
    background-color: white;
    position: relative;
    top: 30px;
  }

  .menu > li:not(:last-child) {
    border-bottom: 1px solid white;
  }
}

main {
  position: relative;
  top: 80px;
}

.heading {
  display: grid;
  justify-content: center;
  width: 100%;
  grid-template: 1fr / 1fr;
  overflow: hidden;
  background-color: rgb(40, 74, 89);
}

.heading .posterImg {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  width: 100%;
  height: 650px;
  align-self: center;
  z-index: 1;
  opacity: 0.4;
}

.heading .posterText {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  width: 40%;
  z-index: 2;
  margin: 0 auto;
  align-self: center;
  text-align: center;
}

.heading .posterText h1 {
  font-family: "Montserrat";
  color: rgb(254, 251, 239);
  margin-bottom: 10px;
  font-size: 2.5em;
}

.heading .posterText p {
  font-family: "Montserrat";
  color: rgb(254, 251, 239);
  font-size: 1.5em;
}

.ourApproach {
  background-color: rgb(254, 251, 239);
}

.ourApproach h2 {
  font-family: "Reddit Sans Condensed";
  text-align: center;
  padding: 30px;
  font-size: 1.7em;
  color: rgb(40, 74, 89);
  text-transform: uppercase;
}

.flexBox {
  background-color: rgb(254, 251, 239);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Aligns items at the start (top) */
  flex-direction: row;
  text-align: center;
  padding: 0 30px;
}

.eachApproach {
  display: inline-block;
  font-family: "Montserrat";
  margin: 30px 0;
  width: 200px;
  height: 300px;
  text-align: center;
  padding: 20px;
  box-sizing: border-box; /* Ensure padding is included in the height */
  overflow: hidden;
  border-radius: 10px;
}

.eachApproach h3 {
  color: rgb(40, 74, 89);
  height: 50px;
  padding-bottom: 10px;
  
}

.eachApproach p {
  height: 200px;
  line-height: 25px;
  color: rgb(40, 74, 89);
}

.whyChooseUs {
  background-color: rgb(40, 74, 89);
  padding: 60px 20px; 
  text-align: left;
  color: rgb(254, 251, 239);
}

.whyChooseUs h2 {
  font-family: "Reddit Sans Condensed"; 
  font-size: 1.7em;
  text-align: center;
  margin-bottom: 40px; 
  color: rgb(255, 255, 255); 
  text-transform: uppercase; 
}

.whyChooseUs .eachReason {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: rgb(40, 74, 89);
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}

.whyChooseUs .eachReason h3 {
  font-family: "Montserrat"; 
  font-size: 1.5em;
  margin-bottom: 10px;
  color: rgb(40, 74, 89);
  width: 20%;
}

.whyChooseUs .eachReason p {
  font-family: "Montserrat";
  font-size: 1.1em;
  line-height: 1.6;
}

.contact {
  background-color: rgb(254, 251, 239);
  width: 100%;
}

.contact h2 {
  text-align: center;
  font-family: "Reddit Sans Condensed";
  color: rgb(40, 74, 89);
  padding: 30px;
  font-size: 1.7em;
  text-transform: uppercase;
}

form {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template: 1fr 2fr 1fr / 1fr 1fr 1fr;
}

input {
  border: none;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  background-color: white;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 10px;
}

input:focus {
  outline: none;
}

input[type="text"] {
  font-family: "Montserrat";
}

input[type="email"] {
  font-family: "Montserrat";
}

input[type="number"] {
  font-family: "Montserrat";
}

input[type="submit"] {
  font-family: "Montserrat";
}


.name {
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
}

.email {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
}

.phone {
  grid-row: 1 / span 1;
  grid-column: 3 / span 1;
}

.message {
  grid-row: 2 / span 1;
  grid-column: 1 / span 3;
}

.submit {
  grid-row: 3 / span 1;
  grid-column: 2 / span 1;
  justify-self: center;
  width: 40%;
  transition: background-color 0.5s;
}

.submit:hover {
  cursor: pointer;
  background-color: rgb(40, 74, 89);
  color: rgb(254, 251, 239);
}

.contactInfo {
  text-align: center;
  padding: 30px;
}

.contactInfo i {
  color: rgb(40, 74, 89);
  position: relative;
  top: 6px;
  padding: 5px;
}

.contactInfo p {
  color: rgb(40, 74, 89);
  font-family: "Montserrat";

}

/* General mobile styles */
@media (max-width: 600px) {
  .leftHeader h2 span {
    display: none;
  }

  .heading .posterText {
    width: 15%;
  }

  .heading .posterText h1 {
    font-size: 2em;
  }

  .heading .posterText p {
    font-size: 1.2em;
  }

  .flexBox {
    flex-direction: column;
    padding: 0 15px;
  }

  .eachApproach {
    width: 90%;
    height: 200px;;
  }

  .whyChooseUs .eachReason {
    flex-direction: column;
    text-align: center;
  }

  .whyChooseUs .eachReason h3 {
    width: 100%;
    margin-bottom: 15px;
  }

  .contact form {
    grid-template: 1fr 1fr 1fr / 1fr;
  }

  .contact .name, 
  .contact .email, 
  .contact .phone, 
  .contact .message, 
  .contact .submit {
    grid-column: 1 / span 1;
    width: 100%;
  }

  .contact .submit {
    width: 80%;
  }
}